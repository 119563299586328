import React from "react";
import {ButtonHTMLAttributes} from "react";
import {motion, MotionProps} from "framer-motion";

const Button = ({variant = "primary", text = "light", animated = false, ...props}: MotionProps & ButtonHTMLAttributes<HTMLButtonElement> & {variant?: "primary" | "secondary", text?: "light" | "dark", animated?: boolean}) => {
    const cls = props.className || "";
    delete props.className;

    return <motion.button className={`button button-${variant} button-${variant === "secondary" ? "nothing" : text} ${cls}`} whileTap={{scale: animated ? 0.95 : 1}} {...props}/>
}

export default Button;
