import { create, StateCreator } from 'zustand'

export interface CardState {
    enabled: boolean;
    setEnabled: (enabled: boolean) => void;
}

export const cardSlice: StateCreator<CardState>  = (set) => ({
    enabled: true,
    setEnabled: (enabled) => set((state) => ({ enabled })),
});

const useStore = create<CardState>((...a) => ({
    ...cardSlice(...a)
}));

export default useStore;
