import {useEffect, useState} from "react";
import {CognitoUser} from "amazon-cognito-identity-js";
import Auth from "../../backend/Auth";

const useUser = (): CognitoUser | null | false => {
    const [user, setUser] = useState<CognitoUser | null | false>(null);

    useEffect(() => {
        if (user === null) {
            setUser(Auth.getUser() || false);
        }

        const handleLogout = () => {
            setUser(false);
        };

        Auth.authEvent.addEventListener("logout", handleLogout);

        return () => {
            Auth.authEvent.removeEventListener("logout", handleLogout);
        };
    }, [user]);

    return user;
}

export default useUser;
