import {Quiz} from "../../../backend/Tables";
import {AnimatePresence, motion} from "framer-motion";
import CleanTeacherQuizCard from "./CleanTeacherQuizCard";
import React from "react";

const CleanTeacherQuizGrid = ({quizzes}: {quizzes: Quiz[] | null | undefined}) => {
    return <>
        {quizzes && <motion.div className="w-full flex flex-col gap-y-6 sm:gap-12 sm:grid sm:grid-cols-2 lg:grid-cols-3 content-between" variants={{show: {transition: {staggerChildren: 0.075}}}} initial="hidden" animate="show" exit="hidden">
            <AnimatePresence mode="sync">
                {quizzes.map((quiz, idx) => <CleanTeacherQuizCard rd={idx % 3} key={quiz.id} quiz={quiz} useable={true}/>)}
            </AnimatePresence>
        </motion.div>}
    </>
}

export default CleanTeacherQuizGrid;
