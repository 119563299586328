import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Socket} from "./UseSocket";

const UseSocketSync = <T extends {id: string | number, [key: string]: any}>(setState: Dispatch<SetStateAction<T[]>>, socket: Socket, name: string) => {
    const [lastTime, setLastTime] = useState(0);

    useEffect(() => {
        socket.on(name + "_create", (d, f, t) => {
            if (t! > lastTime) {
                setLastTime(t!);
                setState(state => [...state, JSON.parse(d) as T]);
            }
        });

        socket.on(name + "_update", (d, f, t) => {
            if (t! > lastTime) {
                setLastTime(t!);
                const data = JSON.parse(d) as T;
                setState(state => state.map(s => s.id === data.id ? data : s));
            }
        });

        socket.on(name + "_delete", (d, f, t) => {
            if (t! > lastTime) {
                setLastTime(t!);
                const data = JSON.parse(d) as string | number;
                setState(state => state.filter(s => s.id !== data));
            }
        });

        socket.on(name + "_set", (d, f, t) => {
            if (t! > lastTime) {
                setLastTime(t!);
                setState(JSON.parse(d) as T[]);
            }
        });

        return () => {
            socket.clear(name + "_create");
            socket.clear(name + "_update");
            socket.clear(name + "_delete");
            socket.clear(name + "_set");
        }
    }, [setState, lastTime]);
}

export default UseSocketSync;
