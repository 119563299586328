import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { AvatarGenerator } from 'random-avatar-generator';
import {CognitoUser} from "amazon-cognito-identity-js";
import {Link, useLocation} from "react-router-dom";
import Auth from "../../../backend/Auth";
import logo from "../../../img/logo.svg";
import useScrollPosition from "../../../hooks/ui/UseScrollPosition";

const routes = [
    { name: 'Home', href: '/', auth: 0 },
    { name: 'Browse', href: '/quizzes', auth: 0 },
    { name: 'Home', href: '/quizzes', auth: 2 },
    { name: 'Join Game', href: '/play', auth: 1 },
    { name: 'Create Quiz', href: '/create', auth: 2 },
]

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function CleanTeacherNavbar({user, intro = false}: {user: CognitoUser | null | false, intro?: boolean}) {
    const location = useLocation().pathname;
    const navigation = routes.filter(route => route.auth === 1 || (route.auth === 0 && !user) || (route.auth === 2 && user)).map(route => ({...route, current: location === route.href}));

    const scrolled = useScrollPosition(75) || !intro;

    const avatar = new AvatarGenerator().generateRandomAvatar(user ? user.getUsername() : "John Doe");

    const uType = user === null ? 0 : (user === false ? 1 : 2);

    return (
        <Disclosure as="nav" className={`w-full fixed z-50 top-0 ${scrolled && "bg-gray-800"}`}>
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                        <div className="relative flex h-16 items-center justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> : <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}
                                </Disclosure.Button>
                            </div>
                            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="flex flex-shrink-0 items-center">
                                    <img className="block h-6 w-auto lg:hidden" src={logo} alt="PopQuiz"/>
                                    <img className="hidden h-6 w-auto lg:block" src={logo} alt="PopQuiz"/>
                                </div>
                                <div className="hidden sm:ml-6 sm:block">
                                    <div className="flex space-x-4">
                                        {navigation.map((item) => (
                                            <Link
                                                key={item.name}
                                                to={item.href}
                                                className={classNames(
                                                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                    'rounded-md px-3 py-2 text-sm font-medium'
                                                )}
                                                aria-current={item.current ? 'page' : undefined}
                                            >
                                                {item.name}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                {uType === 1 && <Link to="/auth/login" className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-sm font-medium">Login</Link>}
                                {uType === 2 && <Menu as="div" className="relative ml-3">
                                    <div>
                                        <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                            <span className="sr-only">Open user menu</span>
                                            <img className="h-8 w-8 rounded-full" src={avatar} alt=""/>
                                        </Menu.Button>
                                    </div>
                                    <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <Menu.Item>
                                                {({ active }) => <a href="#" className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>Your Profile</a>}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => <a href="#" className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>Settings</a>}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => <button onClick={() => {Auth.logout()}} className={classNames(active ? 'bg-gray-100' : '', 'block w-full text-start px-4 py-2 text-sm text-gray-700')}>Sign out</button>}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>}
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="space-y-1 px-2 pb-3 pt-2">
                            {navigation.map((item) => (
                                <Disclosure.Button
                                    key={item.name}
                                    as={Link}
                                    to={item.href}
                                    className={classNames(
                                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                        'block rounded-md px-3 py-2 text-base font-medium'
                                    )}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    {item.name}
                                </Disclosure.Button>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}