import ChatGPT from "./ChatGPT";

class FunctionPrompt<I extends {[key: string]: string}, R extends {[key: string]: any}> {
    readonly prompt: string;
    readonly function: Object;

    constructor(prompt: string, func: Object) {
        this.prompt = prompt.trim();
        this.function = func;
    }

    public use(input: I): string {
        let p = this.prompt.toString();
        Object.keys(input).forEach(key => {
            p = p.replaceAll(`{${key}}`, input[key]);
        });
        return p;
    }

    public async evaluate(input: I): Promise<R> {
        return JSON.parse((await ChatGPT.complete([{role: "user", content: this.use(input)}], this.function)).function_call!.arguments) as R;
    }
}

export default FunctionPrompt;
