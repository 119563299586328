import {useEffect, useState} from "react";

const UseScrollPosition = (scrollOffset: number): boolean => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            setIsScrolled(currentScrollPos > scrollOffset);
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [scrollOffset]);

    return isScrolled;
};

export default UseScrollPosition;
