import React, {ImgHTMLAttributes} from "react";
import diamond from "../../../../img/game/diamond-512.png";
import sqaure from "../../../../img/game/square-512.png";
import circle from "../../../../img/game/circle-512.png";
import triangle from "../../../../img/game/triangle-512.png";

const ICONS = [diamond, sqaure, circle, triangle];
const GameIcon: React.FC<ImgHTMLAttributes<HTMLImageElement> & {type: number}> = (props) => {
    if (!props.className) {
        props = {...props, className: ""};
    }
    if (!/w-.+/.test(props.className!)) {
        props.className += "w-4 md:w-5 lg:w-6";
    }
    if (!/h-.+/.test(props.className!)) {
        props.className += "h-4 md:h-5 lg:h-6";
    }
    return <img {...props} src={ICONS[props.type]}/>
}

export default GameIcon;
