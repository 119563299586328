import {Quiz} from "../../../backend/Tables";
import React from "react";
import {Player} from "../NewGameScreen";
import FullDiv from "react-div-100vh";

const LeaderboardUX = ({player, quiz, question, teacher, players, next}: {player: Player | null, quiz: Quiz | null, question: number, teacher: boolean, players: Player[], next: () => void}) => {
    return <FullDiv className="flex flex-col">
        <div className="flex-1 flex flex-col justify-between gap-y-5 bg-[#46178f]">
            <div className="w-full flex-1 flex flex-col gap-y-10">
                <div className="bg-white shadow-md w-full py-5">
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                        <h1 className="text-center text-3xl font-bold select-none game-font">Scoreboard</h1>
                    </div>
                </div>
                <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 flex-1 w-full">
                    {teacher && <div className="flex justify-end mb-5">
                        <button className="game-button game-button-blue py-1.5 px-4 rounded-md" onClick={next}>Next</button>
                    </div>}

                    {players.map((p, index) => <div key={p.id} className={`w-full rounded-sm shadow-lg mb-2 flex justify-between items-center p-3 ${index === 0 && "bg-white"}`}>
                        <span className={`${index === 0 ? "text-gray-900" : "text-white"} font-semibold game-font text-2xl`}>{p.name || ""}</span>
                        <span className={`${index === 0 ? "text-gray-900" : "text-white"} font-bold game-font text-2xl`}>{p.points}</span>
                    </div>)}
                </div>
            </div>
        </div>

        <div className="w-full bg-white shadow-lg">
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                <div className="relative flex py-4 items-center justify-between">
                    {teacher && <p className="game-font text-gray-900 font-bold text-lg">{question + 1} of {quiz?.questions?.length ?? "..."}</p>}
                    {teacher || <p className="game-font text-gray-900 font-bold text-lg">{player!.name}</p>}
                    {teacher && <p className="game-font font-bold text-md">popquiz.fun</p>}
                    {teacher || <p className="game-font text-white font-bold text-lg bg-gray-900 rounded-md shadow-md px-2.5 py-1">{player?.points ?? ""}</p>}
                </div>
            </div>
        </div>
    </FullDiv>
}

export default LeaderboardUX;
