import React, {useState} from "react";
import "../../css/freshtheme.css";
import {AnimatePresence, motion} from "framer-motion";
import TextareaAutosizeRaw from 'react-textarea-autosize';
import {Helmet} from "react-helmet";
import FullDiv from "react-div-100vh";
import Logo from "../../img/game/logo/logo_text.png";
import "@fontsource/dm-sans/latin.css";
import {generateQuizImage, generateTitleDescription, newGenerateQuizContent} from "../../ai/Prompts";
import Button from "../../ui/Button";
import LoadingSpinner from "../game/view/ui/LoadingSpinner";
import {Cog8ToothIcon} from "@heroicons/react/24/outline";
import API from "../../backend/API";
import useUser from "../../hooks/auth/UseUser";
import {useNavigate} from "react-router-dom";

const TextareaAutosize = motion(TextareaAutosizeRaw);

const CATEGORY_IDS = [
    "other",
    "math",
    "science",
    "english",
    "social studies",
    "history"
]

const CleanTeacherCreateScreen = () => {
    /*const navigate = useNavigate();
    const user = useUser();

    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);

    const [prompt, setPrompt] = useState("");

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    const [quizId, setQuizId] = useState(-1);

    const proceed = () => {
        setLoading(true);
        const x = async () => {

            if (step === 0) {
                // How CFCs impacted global warming, including the ozone layer and greenhouse gases
                const result = await generateTitleDescription.evaluate({prompt});
                console.log(result)
                setTitle(result.title);
                setDescription(result.description);
            }

            if (step === 1 && user) {
                const result = await generateQuizContent.evaluate({title, description});
                const id = await API.quizzes.save({title: title, description: description, topic: prompt, tags: result.tags.join(',') + ',', category: CATEGORY_IDS.indexOf(result.category) || 0, question_count: result.questions.length, questions: result.questions, author: user.getUsername()});

                const iprompt = await generateQuizImage.evaluate({title, description});
                console.log(iprompt);

                setQuizId(id!);

                await API.generateImage(iprompt.prompt, id);
                console.log("Done!");
            }

            setStep(step + 1);
            setLoading(false);
        }

        x().then();
    }

    useEffect(() => {
        if (step >= 3) {
            navigate(`/quiz/${quizId}`);
        }
    }, [step]);*/

    const user = useUser();
    const navigate = useNavigate();

    const [stage, setStage] = useState(0);
    const [status, setStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [prompt, setPrompt] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    const [quizId, setQuizId] = useState(-1);

    const handlePrompt = () => {
        const x = async () => {
            if (!user) {
                navigate("/auth/login");
                return;
            }

            setLoading(true);

            const {title, description} = await generateTitleDescription.evaluate({prompt});
            setTitle(title);
            setDescription(description);

            setLoading(false);
            setStage(2);
        }
        void x();
    }

    const handleConfirm = () => {
        const x = async () => {
            if (!user) {
                navigate("/auth/login");
                return;
            }

            setStage(3);

            setStatus("Writing questions...");
            // const result = await generateQuizContent.evaluate({title, description});
            const result = await newGenerateQuizContent.evaluate({title, description});
            const id = await API.quizzes.save({title: title, description: description, topic: prompt, tags: result.tags.join(',') + ',', category: CATEGORY_IDS.indexOf(result.category) || 0, question_count: result.questions.length, questions: result.questions, author: user.getUsername()});

            setStatus("Dreaming image...");
            const iprompt = await generateQuizImage.evaluate({title, description});

            setStatus("Drawing image...");
            await API.generateImage(iprompt.prompt, id);

            setQuizId(id!);

            setStage(4);
        }
        void x();
    }

    return <>
        <Helmet>
            <body className="bg-orange-200"/>
        </Helmet>
        <AnimatePresence mode="wait">
            <FullDiv className="flex flex-col justify-between items-center" key={stage}>
                <img src={Logo} alt="NOOB" className="h-[4rem] mt-6"/>
                {stage === 0 && <motion.div className="bg-white py-10 px-20 flex flex-col items-center -mt-14 shadow-lg rounded-lg" key={0} initial={{opacity: 0, translateY: "0px"}} animate={{opacity: 1, translateY: "0px"}} exit={{opacity: 0, translateY: "-20px"}}>
                    <motion.p className="create-font text-orange-500 mb-2">Welcome to PopQuiz generator</motion.p>
                    <motion.p className="create-font text-xl font-semibold mb-10">Let's create you a quiz in 1 minute</motion.p>
                    <Button className="!px-12" animated={true} onClick={() => {setStage(1)}}>Start creating</Button>
                </motion.div>}
                {stage === 1 && <motion.div className="bg-white py-6 px-8 flex flex-col -mt-14 shadow-lg rounded-lg" transition={{delay: 0.2}} key={1} initial={{opacity: 0, translateY: "-20px"}} animate={{opacity: 1, translateY: "0px"}} exit={{opacity: 0, translateY: "-20px"}}>
                    <motion.p className="create-font text-xl font-semibold mb-6 text-center">Tell us about your quiz</motion.p>

                    <motion.p className="create-font text-xs font-semibold mb-1">Description</motion.p>
                    <motion.p className="create-font text-xs text-gray-500 mb-3">Describe your quiz in a few words</motion.p>
                    <TextareaAutosize value={prompt} onChange={e => setPrompt(e.target.value)} className="create-font mb-10 min-w-[20rem] px-3 py-2.5 text-sm rounded-md border-orange-500 focus:border-orange-600 focus:border-2 focus:ring-0 focus:outline-0 resize-none" minRows={2} maxRows={8} placeholder="History of basketball"/>

                    <Button className="w-full" animated={true} onClick={handlePrompt} disabled={loading || prompt.length < 10}>{loading ? <LoadingSpinner color="#fcd34d" className="!text-white w-6 h-6"/> : "Continue"}</Button>
                </motion.div>}
                {stage === 2 && <motion.div className="bg-white py-6 px-8 flex flex-col -mt-14 shadow-lg rounded-lg" transition={{delay: 0.2}} key={2} initial={{opacity: 0, translateY: "-20px"}} animate={{opacity: 1, translateY: "0px"}} exit={{opacity: 0, translateY: "-20px"}}>
                    <motion.p className="create-font text-xl font-semibold mb-6 text-center">Confirm these details</motion.p>

                    <motion.p className="create-font text-xs font-semibold mb-3">Title</motion.p>
                    <motion.input value={title} onChange={e => setTitle(e.target.value)} className="create-font mb-8 min-w-[20rem] text-sm rounded-md border-orange-500 focus:border-orange-600 focus:border-2 focus:ring-0 focus:outline-0"/>

                    <motion.p className="create-font text-xs font-semibold mb-3">Description</motion.p>
                    <TextareaAutosize value={description} onChange={e => setDescription(e.target.value)} className="create-font mb-10 min-w-[20rem] px-3 py-2.5 text-sm rounded-md border-orange-500 focus:border-orange-600 focus:border-2 focus:ring-0 focus:outline-0 resize-none" minRows={2}/>
                    <Button className="w-full" animated={true} onClick={handleConfirm} disabled={loading || description.length < 10 || title.length < 5}>{loading ? <LoadingSpinner color="#fcd34d" className="!text-white w-6 h-6"/> : "Continue"}</Button>
                </motion.div>}
                {stage === 3 && <motion.div className="bg-white py-16 px-20 flex flex-col justify-center items-center -mt-14 shadow-lg rounded-lg" transition={{delay: 0.2}} key={3} initial={{opacity: 0, translateY: "-20px"}} animate={{opacity: 1, translateY: "0px"}} exit={{opacity: 0, translateY: "-20px"}}>
                    <motion.p className="create-font text-xl font-semibold flex items-center gap-2 mb-5"><Cog8ToothIcon className="animate-spin w-9 h-9"/>Please wait while we create your quiz</motion.p>
                    <motion.p className="create-font text-2xl font-semibold text-orange-400">{status}</motion.p>
                </motion.div>}
                {stage === 4 && <motion.div className="bg-white py-10 px-20 flex flex-col items-center -mt-14 shadow-lg rounded-lg" transition={{delay: 0.2}} key={4} initial={{opacity: 0, translateY: "-20px"}} animate={{opacity: 1, translateY: "0px"}} exit={{opacity: 0, translateY: "-20px"}}>
                    <motion.p className="create-font text-orange-500 mb-2">Thank you</motion.p>
                    <motion.p className="create-font text-xl font-semibold mb-10">Your quiz is ready!</motion.p>
                    <Button className="!px-12" animated={true} onClick={() => {navigate(`/quiz/${quizId}`)}}>Visit quiz</Button>
                </motion.div>}
                <div/>
            </FullDiv>
        </AnimatePresence>
    </>
}

export default CleanTeacherCreateScreen;
