import {Game, Quiz} from "./Tables";
import {CognitoUser} from "amazon-cognito-identity-js";
import {Dispatch, SetStateAction, useEffect, useState} from "react";

export class Table<T extends {[key: string]: any}> {
    private readonly name: string;

    constructor(name: string) {
        this.name = name;
    }

    private async _fetch(path: string, body: {[key: string]: any}) {
        return await (await fetch(`${API.ENDPOINT}${this.name}/${path}`, {method: "POST", body: JSON.stringify(body)})).json();
    }

    async save(data: Partial<T>): Promise<number> {
        return (await this._fetch("save", data)).id;
    }

    async get(id: number): Promise<T> {
        return (await this._fetch("query", {id, limit: 1}))[0];
    }

    async query(query: {[key: string]: any}, limit: number = 50): Promise<T[]> {
        return (await this._fetch("query", {...query, limit}));
    }
}

class API {
    public static readonly ENDPOINT = "https://tishd59oj5.execute-api.us-east-1.amazonaws.com/prod/";
    public static readonly quizzes = new Table<Quiz>("quizzes");

    static init() {

    }

    static async createGame(quiz: Quiz, user: CognitoUser): Promise<string> {
        return (await (await fetch(this.ENDPOINT + "games/create", {method: "POST", body: JSON.stringify({quiz: quiz.id, username: user.getUsername()})})).json()).id as string;
    }

    static async getGame(id: string): Promise<Game | undefined> {
        const r = await (await fetch(this.ENDPOINT + "games/query", {method: "POST", body: JSON.stringify({id})})).json();
        return r.id && r;
    }

    static async findGame(code: string): Promise<Game | undefined> {
        const r = await (await fetch(this.ENDPOINT + "games/query", {method: "POST", body: JSON.stringify({code})})).json();
        return r.id && r;
    }

    static async generateImage(prompt: string, id: number): Promise<void> {
        return (await (await fetch(this.ENDPOINT + "quizzes/image", {method: "POST", body: JSON.stringify({prompt, key: id})})).json());
    }
}

export const useAPIData = <T extends {[key: string]: any}, L extends number = 50>(table: Table<T>, query: {[key: string]: any} | number, limit: L = 50 as L, cache: (L extends 1 ? T : T[]) | null): [L extends 1 ? T : T[] | null, Dispatch<SetStateAction<L extends 1 ? T : T[] | null>>] => {
    const [state, setState] = useState<(L extends 1 ? T : T[]) | null>(cache);
    const [newState, setNewState] = useState<(L extends 1 ? T : T[]) | null>(null);

    useEffect(() => {
        const x = async () => {
            if (query !== null && query !== undefined) {
                if (typeof query === "number") {
                    query = {id: query};
                }
                const result = await table.query(query, limit);
                setState((limit === 1 ? result[0] : result) as L extends 1 ? T : T[]);
            } else {
                setState(null);
            }
        }

        void x();
    }, [table, query, limit]);

    useEffect(() => {
        if (newState && limit === 1 && (JSON.stringify(newState) !== JSON.stringify(state))) {
            setState(newState);
            void table.save(newState as Partial<T>);
        }
    }, [newState]);

    return [state, setNewState] as [L extends 1 ? T : T[] | null, Dispatch<SetStateAction<L extends 1 ? T : T[] | null>>];
}

export default API;
