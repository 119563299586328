import React from "react";
import {Helmet} from "react-helmet";
import LoadingSpinner from "./ui/LoadingSpinner";

const ConnectingUX = () => {
    return <>
        <Helmet>
            <title>Connecting...</title>
            <body className="bg-purple-800"/>
        </Helmet>
        <div className="flex flex-col items-center absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <LoadingSpinner className="w-24 h-24 stroke-[5rem]"/>
            <p className="game-font font-bold text-3xl sm:text-4xl md:text-5xl text-white mt-4">Connecting...</p>
        </div>
    </>
}

export default ConnectingUX;
