import React from "react";
import {Navigate} from "react-router-dom";
import Auth from "../../backend/Auth";

const LogoutScreen = () => {
    Auth.logout();
    return <Navigate to="/"/>
}

export default LogoutScreen;
