import {AuthenticationDetails, CognitoAccessToken, CognitoIdToken, CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoUserSession, ISignUpResult} from 'amazon-cognito-identity-js';

class Auth {
    static pool: CognitoUserPool;
    static authEvent: EventTarget = new EventTarget();

    static init() {
        this.pool = new CognitoUserPool({
            UserPoolId: "us-east-1_dI0FCJVGc",
            ClientId: "19bkdkep7915r1gp1hnccq7sl4"
        });
    }

    static _createAttributeList(attributes: {[key: string]: string}): CognitoUserAttribute[] {
        const attr: CognitoUserAttribute[] = [];
        Object.keys(attributes).forEach(x => {
            const y = attributes[x];
            attr.push(new CognitoUserAttribute({
                Name: x,
                Value: y
            }));
        });
        return attr;
    }

    static _createCognitoUser(username: string): CognitoUser {
        return new CognitoUser({Pool: this.pool, Username: username});
    }

    static _createAuthenticationDetails(username: string, password: string): AuthenticationDetails {
        return new AuthenticationDetails({Username: username, Password: password});
    }

    static login(email: string, password: string): Promise<CognitoUserSession> {
        return new Promise<CognitoUserSession>((resolve, reject) => {
            const user = this._createCognitoUser(email);
            user.authenticateUser(this._createAuthenticationDetails(email, password), {
                onSuccess: (result) => {
                    resolve(result);
                },
                onFailure: (err) => {
                    reject(err);
                }
            });
        });
    }

    static register(email: string, password: string, attributes: { [key: string]: string }): Promise<ISignUpResult> {
        return new Promise<ISignUpResult>((resolve, reject) => {
            this.pool.signUp(email, password, this._createAttributeList({ email, ...attributes }), [], (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result!);
                }
            });
        });
    }

    static confirm(email: string, code: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const user = this._createCognitoUser(email);
            user.confirmRegistration(code, true, (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            });
        });
    }

    static logout(): Promise<void> {
        this.authEvent.dispatchEvent(new Event("logout"));
        return new Promise<void>((resolve) => {
            const user = this.getUser();
            if (user) {
                user.signOut(() => {
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    static getUser(): CognitoUser | null {
        return this.pool.getCurrentUser();
    }

    static getToken(user: CognitoUser, type: 'ACCESS' | 'ID'): Promise<CognitoAccessToken | CognitoIdToken> {
        return new Promise<CognitoAccessToken | CognitoIdToken>((resolve, reject) => {
            user.getSession((err?: Error | null, session?: CognitoUserSession) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(type === "ACCESS" ? session!.getAccessToken() : session!.getIdToken());
                }
            });
        });
    }
}

export default Auth;
