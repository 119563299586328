import Prompt from "./Prompt";
import {Question} from "../backend/Tables";
import FunctionPrompt from "./FunctionPrompt";

export const generateTitleDescription = new Prompt<{prompt: string}, {title: string, description: string}>(`
Given the topic/description provided by the user, generate a title and description for a pop quiz. The title should be brief and concise (as short as possible), and the description should be concise while including all the key details from the user's input. The title should also preferably include only one key idea, to keep it clean. The pop quizzes are catered towards elementary to high school students and teachers. One example is provided.

The final result must be in JSON format. Expected schema: {title: string, description: string}

User input: The thirteen colonies and the colonization of America.

{
  "title": "The 13 Colonies", 
  "description": "General information on the 13 colonies, as well as their significance in the history of America."
}

###

User input: {prompt}
{
`);

export const newGenerateQuizContent = new FunctionPrompt<{title: string, description: string}, {tags: string[], category: string, questions: Question[]}>(`
Given the title and description, generate a quiz based on the given topic that includes, tags, a category and **exactly \`15\`** questions. Each question should include four answer choices and a list of the correct answers by index (questions can have multiple right answers). Write as many tags as possible. Use the function \`complete\` when done.

Title: {title}
Description: {description}
`, {
    name: "complete", description: "Process the generated quiz", parameters: {
        type: "object",
        properties: {
            category: {
                type: "string",
                enum: ["math", "science", "english", "social studies", "history", "other"],
            },
            tags: {
                type: "array",
                items: {
                    type: "string"
                }
            },
            questions: {
                type: "array",
                description: "Exactly 15 questions",
                items: {
                    type: "object",
                    properties: {
                        question: {
                            type: "string"
                        },
                        choices: {
                            type: "array",
                            items: {
                                type: "string"
                            }
                        },
                        answers: {
                            type: "array",
                            description: "Indexes of correct answer choices",
                            items: {
                                type: "number"
                            }
                        }
                    },
                    required: ["question", "choices", "answers"]
                }
            },
        },
        required: ["category", "tags", "questions"]
    }
});

export const generateQuizImage = new Prompt<{title: string, description: string}, {prompt: string}>(`
Based on the following quiz details, write a prompt that can be used by an AI art generate like DALL-E to generate a cover image for that quiz. The style of the image should be flat, clean, simplistic, vibrant, modern, and almost vector-like.

Examples:

A quiz regarding the impact of COVID-19 (and other diseases) on schools ->
flat vector illustration of teacher pointing at chalkboard, (everyone wearing face masks), clean, simplistic, modern, potted plants, diverse children, no texture, no gradient, no shading

A general quiz on science
flat vector illustration of a scientist working in her lab, clean, simplistic, modern, potted plants, white lab coat, no texture, no gradient, no shading

A quiz on litter and trash (slightly exaggerated prompt because it looks cool as a cover image)
flat vector illustration of a scary and ominous grassy landscape with five or more trees, a large crack in the ground, and a gigantic monster sticking up high above the crack. The monster is based on an oak tree and made up of all kinds of litter and debris, including cans and bottles. The landscape is scattered with lots of litter and debris. The environment is dusty with no texture or shading. The color scheme of the grassy landscape is green and brown.

A quiz on Japanese culture
a anime still of an highly detailed night cyberpunk city life, bladerunner style!! detailed shops, neon lights, ray tracing, advertising everywhere, people and robots walking around. art by satoshi kon and studio ghibli, in the style of ghost in the shell, muted colours, hyperrealism, cinematic lighting, lush detail, award winning, wlop, octane render, trending on artstation

All prompts should include specific details about people and environments, and include details about the desired style of the image. Exaggeration is alright because the images are designed to be attention grabbing. Prompts should use less full sentences, and not require text in the final image (The model cannot generate images with text and has a prompt token limit). Prompts should make no mention of quizzes or viewers of the artwork, but be strictly focused on the image.

Note: The model does not do a good job with high-level concepts so there should be specific details on every bit. For example, images should be still because the model does not know how to handle extended movement (ex dog walking across the park).

Quiz title: {title}
Description: {description}

The final prompt must be in JSON format. Expected schema: {prompt: string}

{
`);

export const generateQuizQuestion = new Prompt<{quiz: string, prompt: string}, {question: string, choices: string[], answers: number[]}>(`
Given the topic/description provided by the user, add exactly one question to a pop quiz. The existing quiz is provided.
Do not write a duplicate question!!! The question should flow with the rest of the quiz.

Example (without full context):
User input: The Mayflower.
{
    "question": "Which of the following was brought to America by the Mayflower?",
    "choices": [
      "The Declaration of Independence",
      "The Bill of Rights",
      "The Constitution",
      "Religious freedom"
    ],
    "answers": [3]
}

The final result must be in JSON format. Expected schema: {question: string, choices: string[], answers: number[]}

Existing quiz:
{quiz}

User input: {prompt}
{
`)
