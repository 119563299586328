import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import useSocket from "../../hooks/socket/UseSocket";
import useUser from "../../hooks/auth/UseUser";
import {Game} from "../../backend/Tables";
import useSocketSync from "../../hooks/socket/UseSocketSync";
import "@fontsource/montserrat/latin-400.css";
import "@fontsource/montserrat/latin-600.css";
import "@fontsource/montserrat/latin-700.css";
import "@fontsource/montserrat/latin-800.css";
import QuestionUX from "./view/QuestionUX";
import LeaderboardUX from "./view/LeaderboardUX";
import LobbyUX from "./view/LobbyUX";
import PodiumUX from "./view/PodiumUX";
import API from "../../backend/API";
import {WifiIcon} from "@heroicons/react/24/solid";
import ConnectingUX from "./view/ConnectingUX";


type Player = {
    id: string;
    gameid: number;
    name?: string;
    points: number;
    deleted?: boolean;
    correct?: boolean;
};

const cyrb53 = (str: string, seed: number = 0) => {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for(let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1  = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2  = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

const NewGameScreen = () => {
    const id = useParams().id;

    const [returnid] = useState(localStorage.returnid || Math.round(Math.random() * 100000));

    const lState = useLocation().state;

    const [socket, open, errored, closeSock] = useSocket("wss://2inrvakaw2.execute-api.us-east-1.amazonaws.com/prod");

    const user = useUser();

    const [game, setGame] = useState<Game | null>(lState?.game ?? null);
    const [quiz, setQuiz] = useState(lState?.quiz || null);

    const [question, setQuestion] = useState(-1);
    const [stage, setStage] = useState(-1);

    const [joined, setJoined] = useState(false);

    const teacher = !!(user && game && user.getUsername() === game.creator);

    const [p, setPlayers] = useState<Player[]>([]);
    const players = p.filter(player => "name" in player && !player.deleted).sort((a, b) => b.points - a.points);
    useSocketSync<Player>(setPlayers, socket, "players");

    const [playerId, setPlayerId] = useState<string | null>(null);
    const [player, setPlayer] = useState<Player | null>(null);

    const [answers, setAnswers] = useState<{[key: string]: number}>({});

    const navigate = useNavigate();

    const [timeSync, setTimeSync] = useState(0);
    const [wantsToClose, setWantsToClose] = useState(false);

    const [lastPong, setLastPong] = useState(Date.now());
    const [poorConnection, setPoorConnection] = useState(false);

    const [lastNextTime, setLastNextTime] = useState(0);

    // todo: reload prevention, reload warning

    useEffect(() => {
        if (open) {
            const interval = setInterval(() => {
                socket.emit("ping", {});

                if (Date.now() - lastPong > (60 * 1000)) {
                    console.log("Poor connection.");
                    setPoorConnection(true);
                } else {
                    setPoorConnection(false);
                }
            }, 30 * 1000);

            return () => {
                clearInterval(interval);
            }
        }
    }, [open, lastPong]);

    useEffect(() => {
        if (errored) {
            console.log("Socket closed!");
            navigate(teacher ? "/" : "/play", {state: {closed: true}});
        }
    }, [errored]);

    const next = () => {
        let s = stage >= 2 ? 0 : stage + 1;
        if (question >= quiz?.questions?.length! - 1 && s === 2) {
            s = 3;
        }
        setStage(s);
        if (s === 0) {
            setQuestion(question + 1);
        }
        socket!.emit('next', {time: Date.now()});
    }

    useEffect(() => {
        if (open && user !== null && game && quiz && !joined) {
            setJoined(true);

            console.log("Connecting...");
            socket.emit('handshake', {gameid: id, teacher, returnid: "R-" + cyrb53(game.id, returnid)});
            localStorage.returnid = returnid;
            localStorage.returngame = game.id;

            socket.on('handshake', (d) => {
                console.log("Connected!");
                const data = JSON.parse(d!) as {player: string};
                setPlayerId(data.player);
            });

            socket.on('answered', (d) => {
                const data = JSON.parse(d!) as {player: string, choice: number};
                setAnswers(answers => ({...answers, [data.player]: data.choice}));
            });

            socket.on('pong', () => {
                setLastPong(Date.now());
            });

            socket.on('debug', (data) => {
                console.log("Socket debug received! " + data);
            });

            socket.on('disconnect', () => {
                console.log("Socket closed!");
                if (!wantsToClose) {
                    navigate(teacher ? "/" : "/play", {state: {closed: true}});
                }
            });
        }
    }, [open, user, game, quiz, joined]);

    useEffect(() => {
        socket.on('next', (d, f, t) => {
            if (t! <= lastNextTime) {
                return;
            }
            setLastNextTime(t!);

            const data = JSON.parse(d!) as {stage: number, question: number, time: number};
            if (data.question < question || (data.stage > 0 && data.stage < stage)) {
                return;
            }
            setQuestion(data.question);
            if (data.stage === 0 && f !== "ping-machine") {
                setAnswers({});
            }
            if (data.question >= quiz.questions?.length! - 1 && data.stage === 2) {
                setStage(3);
            } else {
                setStage(data.stage);
            }
            setTimeSync(Date.now() - data.time);
        });

        return () => {
            socket.clear('next');
        }
    }, [joined, lastNextTime, question, stage]);

    useEffect(() => {
        if (playerId) {
            p.forEach(player => {
                if (player.id === playerId) {
                    setPlayer(player);
                }
            });
        }
    }, [playerId, players]);

    useEffect(() => {
        const x = async () => {
            const g = await API.getGame(id!);
            if (!g) {
                delete localStorage.returngame;
                navigate(teacher ? "/" : "/play", {state: {closed: true}});
                return;
            }
            setGame(g);
        }
        if (!game) {
            void x();
        }
    }, [game]);

    useEffect(() => {
        const x = async () => {
            setQuiz(await API.quizzes.get(game!.quiz));
        }
        if (!quiz && game) {
            void x();
        }
    }, [quiz, game]);

    const close = () => {
        setWantsToClose(true);
        closeSock();
    }

    return <>
        {playerId ? <>
            {stage === -1 && <LobbyUX next={next} players={players} player={player} quiz={quiz} teacher={teacher} socket={socket} code={game?.code}/>}
            {stage === 0 && player && <QuestionUX next={next} player={player} players={players} quiz={quiz!} question={question} teacher={teacher} socket={socket} showAnswers={false} answers={answers} timeSync={timeSync}/>}
            {stage === 1 && player && <QuestionUX next={next} player={player} players={players} quiz={quiz!} question={question} teacher={teacher} socket={socket} showAnswers={true} answers={answers} timeSync={timeSync}/>}
            {stage === 2 && player && <LeaderboardUX next={next} players={players} player={player} quiz={quiz} question={question} teacher={teacher}/>}
            {stage === 3 && player && <PodiumUX close={close} players={players} player={player} quiz={quiz} teacher={teacher} socket={socket}/>}
            {poorConnection && <div className="flex justify-center items-center gap-2 game-font font-bold text-3xl text-white bg-yellow-400 fixed bottom-0 w-screen shadow-md z-[1000] py-5"><WifiIcon className="w-10 h-10"/> Connection limited or unavailable</div>}
        </> : <ConnectingUX/>}
    </>
}

export default NewGameScreen;
export type {
    Player
};
