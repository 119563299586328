import React, {useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import useUser from "../../hooks/auth/UseUser";
import CleanTeacherNavbar from "./view/CleanTeacherNavbar";
import CleanTeacherQuizCard from "./view/CleanTeacherQuizCard";
import {Quiz} from "../../backend/Tables";
import {PlusIcon} from "@heroicons/react/24/outline";
import {generateQuizQuestion} from "../../ai/Prompts";
import CleanTeacherFooter from "./view/CleanTeacherFooter";
import {AnimatePresence, motion} from "framer-motion";
import API, {useAPIData} from "../../backend/API";
import Button from "../../ui/Button";
import LoadingSpinner from "../game/view/ui/LoadingSpinner";

const CleanTeacherQuizScreen = () => {
    const state: {quiz?: Quiz} | undefined = useLocation().state;
    const cache = state?.quiz ?? null;

    const id = Number.parseInt(useParams().id!);
    const user = useUser();

    const [quiz, setQuiz] = useAPIData(API.quizzes, id, 1, cache);

    const [addTopic, setAddTopic] = useState("");
    const [loadingQuestion, setLoadingQuestion] = useState(false);

    const owner = user && quiz && user.getUsername() === quiz.author;

    const addQuestion = async () => {
        if (quiz && user) {
            setLoadingQuestion(true);
            const result = await generateQuizQuestion.evaluate({quiz: JSON.stringify(quiz), prompt: addTopic});
            const nq = {...quiz};
            nq.questions = [...nq.questions!, result];
            setQuiz(nq);
            setAddTopic("");
            setLoadingQuestion(false);
        }
    }

    // todo revamp UX to be like a slideshow where each question can have an image and a custom timer

    return <div className="relative min-h-screen pb-36">
        <CleanTeacherNavbar user={user}/>
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 pt-24 flex flex-col md:flex-row gap-2">
            <div className="px-6 md:px-0 h-fit">
                <CleanTeacherQuizCard quiz={quiz!} placeholder={!quiz} full={true} useable={true} clickable={false}/>
            </div>
            <div className="flex-1 px-6">
                <h1 className="text-3xl font-bold sm:text-4xl mb-8">Questions</h1>

                {owner && <motion.div className="rounded-md shadow-md bg-white mb-5 p-5 relative" initial={{scale: 0.55, opacity: 0.75}} animate={{scale: 1, opacity: 1}}>
                    <motion.svg className="w-10 h-10 absolute -right-14 -top-8 select-none fill-orange-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" id="icons" initial={{scale: 0.65, opacity: 0.55}} animate={{scale: 1, opacity: 1}}><path d="M208,512a24.84,24.84,0,0,1-23.34-16l-39.84-103.6a16.06,16.06,0,0,0-9.19-9.19L32,343.34a25,25,0,0,1,0-46.68l103.6-39.84a16.06,16.06,0,0,0,9.19-9.19L184.66,144a25,25,0,0,1,46.68,0l39.84,103.6a16.06,16.06,0,0,0,9.19,9.19l103,39.63A25.49,25.49,0,0,1,400,320.52a24.82,24.82,0,0,1-16,22.82l-103.6,39.84a16.06,16.06,0,0,0-9.19,9.19L231.34,496A24.84,24.84,0,0,1,208,512Zm66.85-254.84h0Z"/><path d="M88,176a14.67,14.67,0,0,1-13.69-9.4L57.45,122.76a7.28,7.28,0,0,0-4.21-4.21L9.4,101.69a14.67,14.67,0,0,1,0-27.38L53.24,57.45a7.31,7.31,0,0,0,4.21-4.21L74.16,9.79A15,15,0,0,1,86.23.11,14.67,14.67,0,0,1,101.69,9.4l16.86,43.84a7.31,7.31,0,0,0,4.21,4.21L166.6,74.31a14.67,14.67,0,0,1,0,27.38l-43.84,16.86a7.28,7.28,0,0,0-4.21,4.21L101.69,166.6A14.67,14.67,0,0,1,88,176Z"/><path d="M400,256a16,16,0,0,1-14.93-10.26l-22.84-59.37a8,8,0,0,0-4.6-4.6l-59.37-22.84a16,16,0,0,1,0-29.86l59.37-22.84a8,8,0,0,0,4.6-4.6L384.9,42.68a16.45,16.45,0,0,1,13.17-10.57,16,16,0,0,1,16.86,10.15l22.84,59.37a8,8,0,0,0,4.6,4.6l59.37,22.84a16,16,0,0,1,0,29.86l-59.37,22.84a8,8,0,0,0-4.6,4.6l-22.84,59.37A16,16,0,0,1,400,256Z"/></motion.svg>
                    <motion.p className="font-medium text-xl mb-2">Add a question</motion.p>
                    <motion.p className="font-bold text-sm mb-4 text-orange-400">Describe the question topic or simply enter the question</motion.p>
                    <motion.div className="flex w-full">
                        <motion.input disabled={loadingQuestion} type="text" className="border-l-[1px] border-y-[1px] border-r-0 border-orange-500 flex-1 p-2 pl-3 placeholder-black placeholder-opacity-30 focus:placeholder-opacity-20 bg-white rounded-l-md shadow-md focus:ring-orange-500 focus:ring-2 focus:outline-none focus:border-none caret-gray-600 text-gray-800" placeholder="What fuel did the Apollo 11 run on?" value={addTopic} onChange={e => {setAddTopic(e.target.value)}}/>
                        <Button disabled={loadingQuestion} className="!rounded-l-none" onClick={addQuestion}>{loadingQuestion ? <LoadingSpinner color="#fcd34d" className="!text-white w-6 h-6"/> : <PlusIcon className="w-6 h-6"/>}</Button>
                    </motion.div>
                </motion.div>}

                <AnimatePresence>
                    {quiz && quiz.questions!.map(question => <motion.div key={question.question} className="rounded-md shadow-md bg-white mb-5 p-5" initial="hidden" animate="show" exit="hidden" variants={{show: {scale: 1, opacity: 1}, hidden: {scale: 0.55, opacity: 0.75}}}>
                        {/** TODO: show delete button for logged in users */}
                        <motion.p className="font-medium text-xl mb-4">{question.question}</motion.p>
                        <motion.div className="grid grid-rows-2 grid-cols-2 gap-5" initial="hidden" animate="show" variants={{show: {transition: {staggerChildren: 0.1}}}}>
                            {question.choices.map((choice, index) => <motion.p variants={{show: {scale: 1, opacity: 1}, hidden: {scale: 0.55, opacity: 0.75}}} key={choice} className={`p-4 rounded-md shadow-sm w-fit h-fit font-semibold ${question.answers.includes(index) ? "bg-green-200" : "bg-gray-100"}`}>{choice}</motion.p>)}
                        </motion.div>
                    </motion.div>)}
                </AnimatePresence>
            </div>
        </div>

        <CleanTeacherFooter/>
    </div>
}

export default CleanTeacherQuizScreen;
