import useWebSocket, {ReadyState} from "react-use-websocket";
import {useEffect, useState} from "react";


interface Socket {
    on(event: 'connect' | 'disconnect' | string, callback: (data?: any, from?: string, time?: number) => void): void;
    clear(event: 'connect' | 'disconnect' | string): void;
    emit(event: string, data: {[key: string]: any}): void;
}

const useSocket = (url: string): [Socket, boolean, boolean, () => void] => {
    const [listeners, setListeners] = useState<{[key: string]: (data?: any, from?: string, time?: number) => void}>({});
    const [eventTimes, setEventTimes] = useState<{[key: string]: number}>({});

    const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(url);

    useEffect(() => {
        const handle = (msg: string, from: string, time: number) => {
            const {event, message} = JSON.parse(msg);

            if (event === "_batch") {
                (message as string[]).forEach(m => {
                    handle(m, from, time);
                });
                return;
            }

            if ((eventTimes[event] || 0) > time) {
                setEventTimes(prevEventTimes => ({ ...prevEventTimes, [event]: time }));
                return;
            }

            setEventTimes(prevEventTimes => ({ ...prevEventTimes, [event]: time }));

            if (listeners[event]) {
                listeners[event](message, from, time);
            } else {
                console.log(`Unhandled event ${event}! ${message}`);
            }
        }

        if (lastMessage) {
            const {from, time} = JSON.parse(lastMessage.data);
            handle(lastMessage.data, from, time);
        }
    }, [lastMessage]);

    useEffect(() => {
        if (readyState === ReadyState.OPEN) {
            if (listeners["connect"]) {
                listeners["connect"]();
            }
        }

        if (readyState === ReadyState.CLOSED) {
            if (listeners["disconnect"]) {
                listeners["disconnect"]();
            }
        }
    }, [readyState]);


    return [{
        on(event: string, callback: (data?: any, from?: string, time?: number) => void) {
            setListeners(listeners => {
                const l = {...listeners};
                l[event] = callback;
                return l;
            });
        },
        clear(event: "connect" | "disconnect" | string) {
            setListeners(listeners => {
                const l = {...listeners};
                delete l[event];
                return l;
            });
        },
        emit(event: string, data: {[key: string]: any}): void {
            if (readyState === ReadyState.OPEN) {
                sendMessage(JSON.stringify({ action: "event", message: { wEvent: event, wMessage: data}}));
            }
        }
    }, readyState === ReadyState.OPEN, readyState === ReadyState.CLOSED, getWebSocket()?.close as () => void];
}

export default useSocket;
export type {
    Socket
};
