import React, {useState} from "react";
import {Quiz} from "../../../backend/Tables";
import {Socket} from "../../../hooks/socket/UseSocket";
import {Player} from "../NewGameScreen";
import {UserIcon} from "@heroicons/react/24/solid";
import logo from "../../../img/game/logo/KahootLogo_Full_white.png";
import FullDiv from 'react-div-100vh'

const LobbyUX = ({player, quiz, teacher, socket, code, players, next}: {player: Player | null, quiz: Quiz | null, teacher: boolean, socket?: Socket, code?: string, players: Player[], next: () => void}) => {
    const [name, setName] = useState("");

    const join = () => {
        if (name.length > 0) {
            socket!.emit('join', {name});
            setName("");
        }
    }

    const kick = (id: string) => {
        if (teacher) {
            socket!.emit('kick', {player: id});
        }
    }

    return <>
        <FullDiv className="flex flex-col">
            <div className="flex-1 flex flex-col bg-purple-900">
                {teacher && <div className="bg-purple-800 pt-4 pb-6 flex flex-col items-center">
                    <h2 className="game-font font-bold text-2xl text-white mb-2">Game PIN:</h2>
                    <h1 className="game-font font-extrabold text-7xl text-black p-4 rounded-sm bg-white tracking-wider">{code ? code.toUpperCase() : "..."}</h1>
                </div>}
                <div className="flex-1 flex flex-col py-6 mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 w-full">
                    <div className="grid grid-cols-12">
                        <div className="col-span-1 mt-4">
                            <p className="p-1.5 h-fit w-fit rounded-md flex items-center gap-1.5 font-bold game-font text-white text-xl sm:text-2xl filter backdrop-brightness-[80%]"><UserIcon className="w-5 h-5 sm:w-6 sm:h-6"/>{players.length}</p>
                        </div>
                        <div className="col-span-10">
                            <img src={logo} alt="suck it Kahoot garbage flaming hot bag of trash" className="mx-auto max-h-[4.35rem] mb-7"/>
                        </div>
                        <div className="col-span-1 flex justify-end">
                            {teacher && <button className="game-button game-button-white py-1.5 px-4 rounded-md h-fit" onClick={next}>Next</button>}
                        </div>
                    </div>
                    <div className={`flex-1 ${players.length <= 0 && "flex justify-center items-center"}`}>
                        {players.length <= 0 && <p className="text-white game-font font-bold text-2xl md:text-3xl -mt-48">Waiting for players...</p>}
                        {players.length > 0 && <div className="py-6 flex flex-wrap gap-6">
                            {players.map(p => <p key={p.id} className={`p-2 rounded-md bg-white shadow-sm game-font text-xl font-bold ${teacher && "cursor-pointer hover:scale-110 transition-transform"}`} onClick={() => {kick(p.id)}}>{p.name}</p>)}
                        </div>}
                    </div>
                </div>
            </div>
            {player?.name && <div className="w-full bg-white shadow-lg">
                <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                    <div className="relative flex py-4 items-center justify-between">
                        {<p className="game-font text-gray-900 font-bold text-lg">{player!.name}</p>}
                        {<p className="game-font text-white font-bold text-lg bg-gray-900 rounded-md shadow-md px-2.5 py-1">{player?.points ?? ""}</p>}
                    </div>
                </div>
            </div>}
        </FullDiv>
        {!teacher && !player?.name && <div className="fixed bottom-0 sm:bottom-10 left-1/2 -translate-x-1/2 py-6 sm:py-8 px-6 w-screen sm:w-fit min-w-[20rem] bg-purple-700 rounded-t-lg sm:rounded-b-lg">
            <h3 className="text-white game-font text-center font-bold text-xl sm:text-2xl mb-5">Your nickname is...</h3>
            <input className="game-input w-full mb-2 text-lg" placeholder="Student 1" value={name} onChange={e => {setName(e.target.value)}}/>
            <button disabled={name.length <= 0} className="game-button game-button-green w-full py-2 rounded-md text-lg" onClick={join}>Ok, go!</button>
        </div>}
    </>
}

export default LobbyUX;
