import React, {Fragment, useEffect, useState} from "react";
import CleanTeacherNavbar from "./view/CleanTeacherNavbar";
import useUser from "../../hooks/auth/UseUser";
import {Quiz} from "../../backend/Tables";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import {BanknotesIcon, BeakerIcon, BookOpenIcon, HomeIcon, MagnifyingGlassIcon, NewspaperIcon, VariableIcon} from "@heroicons/react/24/outline";
import CleanTeacherQuizGrid from "./view/CleanTeacherQuizGrid";
import CleanTeacherFooter from "./view/CleanTeacherFooter";
import API from "../../backend/API";
import Button from "../../ui/Button";


const CATEGORY_TITLES = [
    "All",
    "Math",
    "Science",
    "English",
    "Social Studies",
    "History"
]

const CATEGORY_ICONS = [
    <HomeIcon className="w-5 h-5"/>,
    <VariableIcon className="w-5 h-5"/>,
    <BeakerIcon className="w-5 h-5"/>,
    <BookOpenIcon className="w-5 h-5"/>,
    <BanknotesIcon className="w-5 h-5"/>,
    <NewspaperIcon className="w-5 h-5"/>
]

const CleanTeacherQuizzesScreen = () => {
    const user = useUser();


    const [category, setCategory] = useState(0);
    const [search, setSearch] = useState("");
    const [query, setQuery] = useState("");
    const [quizzes, setQuizzes] = useState<Quiz[] | undefined>();

    useEffect(() => {
        const x = async () => {
            setQuizzes(await API.quizzes.query({category, query}));
        }
        void x();
    }, [query, category]);


    const updateQuery = () => {
        setQuery(search);
    }

    return <div className="relative min-h-screen pb-36">
        <CleanTeacherNavbar user={user}/>
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 pt-24">

            <div className="md:flex mb-12 md:shadow-md md:rounded-lg">
                <div className="w-full md:w-52 lg:w-60 mb-2 md:mb-0 shadow-lg md:shadow-none rounded-lg md:rounded-none border-l-orange-200 border-l-2 md:border-l-0 border-y-0 border-r-0">
                    <Listbox value={category} onChange={setCategory}>
                        <div className="relative">
                            <Listbox.Button className="relative w-full cursor-pointer rounded-l-lg rounded-r-lg md:rounded-r-none bg-white py-3 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                <span className="flex items-center gap-3 truncate">{CATEGORY_ICONS[category]} {CATEGORY_TITLES[category]}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                </span>
                            </Listbox.Button>
                            <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-[1000]">
                                    {CATEGORY_TITLES.map((title, index) => (
                                        <Listbox.Option key={index} className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'}`} value={index}>
                                            {({ selected }) => <>
                                                    <span className={`truncate flex items-center gap-3 ${selected ? 'font-medium' : 'font-normal'}`}>{CATEGORY_ICONS[index]} {title}</span>
                                                    {selected ? (<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"><CheckIcon className="h-5 w-5" aria-hidden="true" /></span>) : null}
                                                </>
                                            }
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </Listbox>
                </div>
                <div className="flex flex-1">
                    <input type="text" className="shadow-lg md:shadow-none rounded-l-lg md:rounded-none flex-1 p-2 pl-3 placeholder-black placeholder-opacity-30 focus:placeholder-opacity-20 bg-white border-l-orange-200 border-l-2 border-y-0 border-r-0 focus:ring-orange-500 focus:ring-2 focus:outline-none focus:border-orange-500 caret-gray-600 text-gray-800" placeholder="Search" value={search} onChange={e => {setSearch(e.target.value)}}/>
                    <Button className="!rounded-l-none" onClick={updateQuery}><MagnifyingGlassIcon className="w-6 h-6"/></Button>
                </div>
            </div>

            <CleanTeacherQuizGrid quizzes={quizzes}/>
        </div>

        <CleanTeacherFooter/>
    </div>
}

export default CleanTeacherQuizzesScreen;
