import {Quiz} from "../../../backend/Tables";
import {Socket} from "../../../hooks/socket/UseSocket";
import React, {useCallback, useEffect, useState} from "react";
import {useTimer} from "react-timer-hook";
import {CheckIcon} from "@heroicons/react/24/solid";
import GameIcon from "./ui/GameIcon";
import {Player} from "../NewGameScreen";
import LoadingSpinner from "./ui/LoadingSpinner";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import FullDiv from 'react-div-100vh'
import {Helmet} from "react-helmet";

const QUESTION_TIME = 30;
const BANNER_TIME = 5;

const QuestionUX = ({player, players, quiz, question, teacher, socket, showAnswers, answers, next, timeSync}: {player: Player, players: Player[], quiz: Quiz, question: number, teacher: boolean, socket?: Socket, showAnswers: boolean, answers: {[key: string]: number}, next: () => void, timeSync: number}) => {
    const q = quiz.questions![question];

    const timerExpire = useCallback(() => {
        if (teacher) {
           next();
        }
    }, [teacher, next]);

    const {seconds, start, pause} = useTimer({expiryTimestamp: new Date(Date.now() + ((QUESTION_TIME + BANNER_TIME) * 1000) - (teacher ? 0 : timeSync)), autoStart: false, onExpire: timerExpire});

    const [answered, setAnswered] = useState(-1);

    const [counts, setCounts] = useState<number[]>([]);
    const [ratios, setRatios] = useState<number[]>([]);
    const [maxRatio, setMaxRatio] = useState<number>(-1);

    useEffect(() => {
        if (showAnswers && answers) {
            const r = [0, 0, 0, 0];

            const values = Object.values(answers);
            const length = values.length;
            values.map(value => {
                r[value] = r[value] + 1;
            });

            setCounts(r);
            setRatios(r.map(rt => rt / length));
            setMaxRatio(Math.max(...r) / length);
        }
    }, [showAnswers, answers]);

    const answer = useCallback((index: number) => {
            if (!teacher) {
                setAnswered(index);
                const c = q.answers.includes(index);
                socket!.emit('answer', { correct: c, choice: index });
            }
        },
        [teacher, q, socket]
    );

    useEffect(() => {
        if (!showAnswers) {
            start();
        }
    }, []);

    /*useEffect(() => {
        if (teacher && !showAnswers && Object.entries(answers).length >= players.length) {
            pause();
            next();
        }
    }, [players, answers, next, teacher, showAnswers]);*/

    // todo: add sound

    if (seconds > QUESTION_TIME && !showAnswers) {
        return <>
            <Helmet>
                <title>Question {`${question + 1}`}</title>
            </Helmet>
            <FullDiv className="flex flex-col pb-10">
                <div className="bg-white shadow-md w-full">
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-5">
                        <h1 className="text-center text-3xl font-bold select-none game-font">Question {question + 1} of {quiz?.questions?.length ?? "..."}</h1>
                    </div>
                    <div className="w-full h-4 bg-gray-300">
                        <div className="h-full bg-purple-800" style={{width: (((seconds - QUESTION_TIME) / BANNER_TIME) * 100) + "%", transition: "width 1s linear"}}/>
                    </div>
                </div>
                <div className="flex-1 flex justify-center items-center">
                    <h2 className="text-center text-xl md:text-2xl lg:text-3xl font-bold select-none game-font">{q?.question ?? ""}</h2>
                </div>
            </FullDiv>
        </>
    }

    if (showAnswers && !teacher) {
        return <>
            <Helmet>
                <title>{player.correct ? "Right!" : "Wrong!"}</title>
                <body className={player.correct ? "bg-green-700" : "bg-red-700"}/>
            </Helmet>
            <FullDiv className="flex flex-col">
                <div className="flex-1 flex justify-center items-center pb-10">
                    <div className="flex flex-col items-center text-white font-bold game-font text-6xl">
                        {player.correct ? <CheckCircleIcon className="w-48 h-48"/> : <XCircleIcon className="w-48 h-48"/>}
                        <p className="mt-4">{player.correct ? "Correct" : "Wrong"}</p>
                    </div>
                </div>
                <div className="w-full bg-white shadow-lg">
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                        <div className="relative flex py-4 items-center justify-between">
                            {<p className="game-font text-gray-900 font-bold text-lg">{player!.name}</p>}
                            {<p className="game-font text-white font-bold text-lg bg-gray-900 rounded-md shadow-md px-2.5 py-1">{player?.points ?? ""}</p>}
                        </div>
                    </div>
                </div>
            </FullDiv>
        </>
    }

    if (answered >= 0) {
        return <>
            <Helmet>
                <body className="bg-purple-800"/>
            </Helmet>
            <FullDiv className="flex flex-col">
                <div className="flex-1 flex justify-center items-center pb-10">
                    <div className="flex flex-col items-center text-white font-bold game-font text-4xl">
                        <LoadingSpinner className="w-24 h-24"/>
                        <p className="mt-5">Genius?!?!</p>
                    </div>
                </div>
                <div className="w-full bg-white shadow-lg">
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                        <div className="relative flex py-4 items-center justify-between">
                            {<p className="game-font text-gray-900 font-bold text-lg">{player!.name}</p>}
                            {<p className="game-font text-white font-bold text-lg bg-gray-900 rounded-md shadow-md px-2.5 py-1">{player?.points ?? ""}</p>}
                        </div>
                    </div>
                </div>
            </FullDiv>
        </>
    }

    return <>
        <Helmet>
            <title>Question {`${question + 1}`}</title>
        </Helmet>
        <FullDiv className="flex flex-col">
            <div className="flex-1 flex flex-col gap-y-2 sm:gap-y-3.5 md:gap-y-5">
                <div className="w-full flex-1 flex flex-col gap-y-5">
                    <div className="bg-white shadow-md w-full py-5">
                        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                            <h1 className="text-center text-xl md:text-2xl lg:text-3xl font-bold select-none game-font">{q?.question ?? ""}</h1>
                        </div>
                    </div>
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 flex-1 w-full grid grid-cols-4 md:grid-cols-5">
                        <div className={`col-span-1 h-full flex-col justify-center hidden sm:flex`}>
                            {!showAnswers && <p className="game-font text-white font-bold my-auto rounded-[100%] w-12 h-12 md:w-16 md:h-16 lg:w-20 lg:h-20 bg-purple-800 text-xl md:text-2xl lg:text-3xl flex justify-center items-center">{seconds}</p>}
                        </div>
                        <div className={`${showAnswers ? "col-span-3" : "col-span-2"} col-span-4 sm:col-span-2 md:col-span-3 flex justify-center ${showAnswers ? "items-end" : "items-center"} h-full`}>
                            {showAnswers && <div className="grid grid-cols-4 gap-2 h-full pb-12 max-h-64 xl:max-h-full">
                                {[0,1,2,3].map(choice => <div key={choice} className="w-20 sm:w-28 lg:w-32 flex flex-col justify-end items-center" style={{minHeight: (maxRatio * 100) + "%"}}>
                                    <p className={`game-font font-bold text-3xl sm:text-4xl md:text-5xl mb-2 flex gap-1 items-center ${choice === 0 && "text-blue-700"} ${choice === 1 && "text-green-700"} ${choice === 2 && "text-yellow-500"} ${choice === 3 && "text-red-700"}`}>{q?.answers.includes(choice) && <CheckIcon className="w-9 h-9 sm:w-12 sm:h-12"/>} <span>{counts[choice]}</span></p>
                                    <div className={`w-full rounded-sm min-h-6 mb-0.5 ${choice === 0 && "bg-blue-700"} ${choice === 1 && "bg-green-700"} ${choice === 2 && "bg-yellow-500"} ${choice === 3 && "bg-red-700"}`} style={{height: (ratios[choice] * 100) + "%"}}/>
                                    <div className={`w-full rounded-sm flex justify-center py-2 text-white text-5xl ${choice === 0 && "bg-blue-700"} ${choice === 1 && "bg-green-700"} ${choice === 2 && "bg-yellow-500"} ${choice === 3 && "bg-red-700"}`}><GameIcon type={choice}/></div>
                                </div>)}
                            </div>}
                            {!showAnswers && <img src="https://cdn.mos.cms.futurecdn.net/oRMtEHPj8CxAvJ5P9BUp36-970-80.jpg.webp" alt="placeholder branding image" className="w-full max-w-full sm:max-w-xs md:max-w-sm xl:max-w-md 2xl:max-w-2xl 4xl:max-w-4xl rounded-sm shadow-md 2xl:h-max object-contain"/>}
                        </div>
                        <div className="col-span-1 h-full flex flex-col justify-between">
                            <div className="w-full h-fit flex justify-start sm:justify-end">
                                {teacher && <button className="game-button game-button-blue py-1.5 px-4 rounded-md mt-5" onClick={next}>Next</button>}
                            </div>
                            <div className="w-full h-fit hidden sm:flex justify-center">
                                {teacher && <div className="text-center font-bold game-font text-black">
                                    <p className="text-xl md:text-2xl lg:text-3xl">{Object.keys(answers).length}</p>
                                    <p className="text-lg md:text-xl lg:text-2xl">Answer{Object.keys(answers).length === 1 ? "" : "s"}</p>
                                </div>}
                            </div>
                            <div/>
                        </div>
                    </div>
                </div>
                <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 w-full pb-4">
                    {!showAnswers && <div className="grid grid-cols-2 grid-rows-2 gap-2 w-full">
                        {q?.choices && q.choices.map((choice, idx) => <motion.button initial={{scale: 0}} animate={{scale: 1}} onClick={() => {answer(idx)}} disabled={teacher} key={choice} className={`game-font w-full h-full rounded-md select-none text-2xl md:text-3xl py-10 sm:py-5 p-5 lg:p-8 xl:p-10 game-button flex justify-center sm:justify-start items-center sm:gap-3 ${idx === 0 && "game-button-blue"} ${idx === 1 && "game-button-green"} ${idx === 2 && "game-button-yellow"} ${idx === 3 && "game-button-red"}`}>
                            <GameIcon type={idx} className="w-10 h-10 sm:w-6 sm:h-6 md:w-8 md:h-8 lg:w-10 lg:h-10"/>
                            <span className="hidden sm:block">{choice}</span>
                        </motion.button>)}
                    </div>}
                    {showAnswers && <div className="grid grid-cols-2 grid-rows-2 gap-2 w-full">
                        {q?.choices && q.choices.map((choice, idx) => <button disabled={true} key={choice} className={`game-font w-full h-full rounded-md select-none text-2xl md:text-3xl py-10 sm:py-5 p-5 lg:p-8 xl:p-10 game-button flex justify-center sm:justify-between items-center ${idx === 0 && "game-button-blue"} ${idx === 1 && "game-button-green"} ${idx === 2 && "game-button-yellow"} ${idx === 3 && "game-button-red"} ${!q.answers.includes(idx) && "opacity-50"}`}>
                            <div className="flex justify-center sm:justify-start items-center sm:gap-3">
                                <GameIcon type={idx} className="w-10 h-10"/>
                                <span className="hidden sm:block">{choice}</span>
                            </div>
                            <div className="hidden sm:block">
                                {q?.answers.includes(idx) && <CheckIcon className="text-white w-12 h-12"/>}
                            </div>
                        </button>)}
                    </div>}
                </div>
            </div>

            <div className="w-full bg-white shadow-[0_-0.5px_12px_rgba(0,0,0,0.1)]">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="relative flex py-4 items-center justify-between">
                        <p className="game-font text-gray-900 font-bold text-lg">{question + 1} of {quiz?.questions?.length ?? "..."}</p>
                        {teacher && <p className="game-font font-bold text-md">popquiz.fun</p>}
                        {teacher || <p className="game-font text-white font-bold text-lg bg-gray-900 rounded-md shadow-md px-2.5 py-1">{player?.points ?? ""}</p>}
                    </div>
                </div>
            </div>
        </FullDiv>
    </>
}

export default QuestionUX;
