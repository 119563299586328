import ChatGPT from "./ChatGPT";

class Prompt<I extends {[key: string]: string}, R extends {[key: string]: any}> {
    prompt: string;

    constructor(prompt: string) {
        this.prompt = prompt.trim();
    }

    public use(input: I): string {
        let p = this.prompt.toString();
        Object.keys(input).forEach(key => {
            p = p.replaceAll(`{${key}}`, input[key]);
        });
        return p;
    }

    public async evaluate(input: I): Promise<R> {
        return ChatGPT.process<R>((await ChatGPT.complete([{role: "user", content: this.use(input)}])).content);
    }
}

export default Prompt;
