import React from "react";
import CleanTeacherNavbar from "./view/CleanTeacherNavbar";
import CleanTeacherIntro from "./view/CleanTeacherIntro";
import "../../css/freshtheme.css";
import useUser from "../../hooks/auth/UseUser";
import {Navigate} from "react-router-dom";
import CleanTeacherMarketing from "./view/CleanTeacherMarketing";

const CleanTeacherHomeScreen = ({}) => {
    const user = useUser();

    if (user) {
        return <Navigate to="/quizzes"/>
    }

    return <>
        <CleanTeacherNavbar user={user} intro={false}/>
        <CleanTeacherIntro/>
        <CleanTeacherMarketing/>
    </>
}

export default CleanTeacherHomeScreen;
