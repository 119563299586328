import { jsonrepair } from 'jsonrepair'

class ChatGPT {
    static process<T>(content: string): T {
        if (!content.startsWith("{")) {
            content = "{" + content;
        }
        return JSON.parse(jsonrepair(content)) as T;
    }

    static async complete(input: Message[], func?: Object): Promise<Message> {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // @ts-ignore
                Authorization: `Bearer ${import.meta.env.VITE_OPENAI_KEY}`,
            },
            body: JSON.stringify({
                model: "gpt-3.5-turbo",
                messages: input,
                ...(func ? {functions: [func], function_call: "auto"} : {})
            }),
        };

        const response = await fetch("https://api.openai.com/v1/chat/completions", requestOptions);
        const data = await response.json();

        console.log(data.choices[0]);
        return data.choices[0].message;
    }
}

type Message = {
    role: "assistant" | "user" | "system";
    content: string;
    function_call?: {arguments: string};
}

export default ChatGPT;
export type {
    Message
};
