import React from "react";
import {Link} from "react-router-dom";
import logo from "../../../img/logo.svg";


export default function CleanTeacherIntro() {

    return <div className="bg-gray-900 w-full mt-10">
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                <div className="relative isolate overflow-hidden pt-16 md:pt-24 lg:flex lg:gap-x-28 lg:pt-0">
                    <svg viewBox="0 0 1024 1024" className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0" aria-hidden="true">
                        <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                        <defs>
                            <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                                <stop stopColor="#7775D6" />
                                <stop offset={1} stopColor="#E935C1" />
                            </radialGradient>
                        </defs>
                    </svg>
                    <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
                        <h1 className="text-3xl font-bold tracking-tight text-white sm:text-4xl"><p className="text-orange-200 mb-2">Generate quizzes on any topic instantly with AI.</p>Make learning fun with games, not tests.</h1>
                        <p className="mt-6 text-lg leading-8 text-gray-300">Teach <span className="line-through text-orange-300 mr-1.5"><span className="text-gray-300">or</span></span><b className="text-orange-300">and</b> have fun. PopQuiz allows you to engage as many students as needed on any subject or topic. We believe you should not have to waste your time making learning materials.</p>
                        <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                            <Link to="/create" className="button button-primary button-dark">Create a quiz</Link>
                            <Link to="/quizzes" className="button button-secondary button-dark">Explore content <span aria-hidden="true">→</span></Link>
                        </div>
                    </div>
                    <div className="relative mt-16 h-80 lg:mt-8">
                        <img className="absolute left-0 top-0 w-[33rem] max-w-none" src={logo} alt="PopQuiz"/>
                    </div>
                </div>
            </div>
        </div>
}