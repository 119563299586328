import {ArrowPathIcon, BoltIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon, PuzzlePieceIcon} from '@heroicons/react/24/outline'
import React from 'react';

const features = [
    {
        name: 'Instant quiz generation',
        description:
            'Generate quizzes on any topic in seconds.',
        icon: BoltIcon,
    },
    {
        name: 'Interactive quiz experience',
        description:
            'Allow your students to have fun while reviewing for their next big test, with social and competitive games.',
        icon: PuzzlePieceIcon,
    },
    {
        name: 'Customizable quizzes',
        description:
            'Tailor your quizzes to best match your needs, with as much granularity as you need.',
        icon: ArrowPathIcon,
    },
    {
        name: 'Performance tracking',
        description:
            'Track student progress and identify areas for improvement or more review.',
        icon: FingerPrintIcon,
    },
]

const CleanTeacherMarketing = () => {
    return (
        <div className="py-12 sm:py-16 bg-hero-light">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                    <h2 className="text-base font-semibold leading-7 text-orange-500">Review material faster</h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Everything you need to engage students.</p>
                    <p className="mt-6 text-lg leading-8 text-gray-600">No matter the size of your class or the subject you are trying to teach, PopQuiz allows you engage your students with review and study material with minimal effort.</p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative pl-16">
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-orange-400">
                                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>

                <div className="mx-auto max-w-2xl lg:text-center mt-20">
                    <h2 className="text-base font-semibold leading-7 text-orange-500">Free. Forever</h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Time is money.</p>
                    <p className="mt-6 text-lg leading-8 text-gray-600">The process of making learning materials is, time consuming, to say the least. With PopQuiz, you can automate the process of generating review and test prep material, for free.</p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative pl-16">
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-orange-400">
                                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}

export default CleanTeacherMarketing;
