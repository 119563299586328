import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Auth from "./backend/Auth";
import * as Sentry from "@sentry/react";
import {createBrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from "react-router-dom";
import API from "./backend/API";

Sentry.init({
    dsn: "https://36ec26cda991480f80362bf65069c96d@o4505433482985472.ingest.sentry.io/4505433486065664",
    integrations: [
        new Sentry.BrowserTracing({
            tracePropagationTargets: ["localhost", /^https:\/\/popquiz.ai/, /^https:\/\/popquiz.fun/],
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            ),
        }),
        new Sentry.Replay({maskAllText: false
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

API.init();
Auth.init();

ReactDOM.createRoot(document.getElementById('root')!).render(<App createBrowserRouter={Sentry.wrapCreateBrowserRouter(createBrowserRouter)}/>)
