import React, {useState} from "react";
import {PlayIcon, Square2StackIcon} from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import {useNavigate} from "react-router-dom";
import {Quiz} from "../../../backend/Tables";
import useUser from "../../../hooks/auth/UseUser";
import API from "../../../backend/API";
import useStore from "../../../store/store";
import LoadingSpinner from "../../game/view/ui/LoadingSpinner";
import Button from "../../../ui/Button";

const CleanTeacherQuizCard = ({quiz, placeholder = false, useable = false, full = false, rd, clickable = true}: {quiz: Quiz, placeholder?: boolean, full?: boolean, useable?: boolean, rd?: number, clickable?: boolean}) => {
    const navigate = useNavigate();
    const user = useUser();
    const {enabled: buttonsEnabled, setEnabled: setButtonsEnabled} = useStore(state => state);
    clickable = buttonsEnabled && clickable;

    const [loading, setLoading] = useState(true);
    const [playLoading, setPlayLoading] = useState(false); // todo: add loading popup
    const [copyLoading, setCopyLoading] = useState(false);

    const imageLoad = () => {
        setLoading(false);
    }

    // @ts-ignore
    const play = async (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        if (!useable) {
            return
        }

        if (!user) {
            navigate("/auth/login");
            return;
        }

        setButtonsEnabled(false);
        setPlayLoading(true);

        try {
            const ngid = await API.createGame(quiz, user);
            navigate(`/play/${ngid}`, {state: {quiz}});
        } catch (e) {
            setPlayLoading(false);
        } finally {
            setButtonsEnabled(true);
        }
    }

    // @ts-ignore
    const copy = async (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        if (!useable) {
            return
        }

        if (!user) {
            navigate("/auth/login");
            return;
        }

        setButtonsEnabled(false);
        setCopyLoading(true);

        const q: Partial<Quiz> = {...quiz};
        delete q.id;
        q.author = user.getUsername();
        const id = await API.quizzes.save(q);

        setButtonsEnabled(true);

        navigate(`/quiz/${id}`);
    }

    const openQuiz = () => {
        if (useable && clickable) {
            navigate(`/quiz/${quiz.id}`, {state: {quiz}});
        }
    }

    const cx = rd !== null ? `mx-auto md:${rd === 0 ? "mr-auto" : "ml-auto"} lg:${rd === 0 ? "mr-auto" : (rd === 1 ? "mx-auto" : "ml-auto")}` : "";

    return <motion.div className={`group w-full h-full max-w-[21rem] md:max-w-[18rem] lg:max-w-[21rem] xl:max-w-[22rem] flex ${full ? "flex-row md:flex-col" : "flex-col"} ${useable && clickable && "cursor-pointer"} ${cx}`} title={quiz && quiz.description} onClick={openQuiz} initial="hidden" animate="show" exit="hidden" whileHover={clickable ? "hover" : undefined} whileTap={clickable ? "tap" : undefined} variants={{hidden: {scale: 0.5, opacity: 0}, show: {scale: 1, opacity: 1}, hover: {scale: 1.05, opacity: 1}, tap: {scale: 0.99, opacity: 1}}}>
        {(loading || placeholder) && <motion.div className="bg-gray-200 rounded-t-lg dark:bg-gray-700 animate-pulse w-full pt-[100%] relative">
            <motion.svg className="w-12 h-12 text-gray-300 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512">
                <motion.path
                    d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/>
            </motion.svg>
        </motion.div>}
        {!placeholder && <motion.img src={`https://popquizimages.s3.amazonaws.com/cover${quiz.id}.png`} alt={quiz.title} className={`${full ? "rounded-l-lg md:rounded-t-lg md:rounded-bl-none shadow-md md:shadow-none" : "rounded-t-lg"} w-max ${loading && "hidden"}`} onLoad={imageLoad}/>}
        <motion.div className={`p-6 flex-1 flex flex-col justify-between gap-4 bg-white ${full ? "rounded-r-lg md:rounded-b-lg md:rounded-tr-none" : "rounded-b-lg"} shadow-md ${clickable && "group-hover:shadow-xl"}`}>
            <div>
                {!quiz || quiz.title!.length === 0 ? <motion.div className="h-4 max-w-[14rem] animate-pulse bg-gray-200 rounded-sm"></motion.div> : <motion.p className="text-2xl font-bold">{quiz.title!}</motion.p>}
                {full && (!quiz || !quiz.description || quiz.description!.length === 0 ? <motion.div className="h-4 max-w-[18rem] animate-pulse bg-gray-200 rounded-sm mb-2 mt-5"></motion.div> : <motion.p className="text-lg mt-3 text-gray-600 font-semibold">{quiz.description!}</motion.p>)}
            </div>
            {useable && <motion.div className="flex gap-4">
                <Button onClick={play} disabled={!buttonsEnabled} text="dark">{playLoading ? <LoadingSpinner className="w-4 h-4 !text-gray-50" color="#fdba74"/> : <PlayIcon className="w-4 h-4"/>} Play</Button>
                <Button onClick={copy} disabled={!buttonsEnabled} variant="secondary">{copyLoading ? <LoadingSpinner className="w-4 h-4 !text-gray-50" color="#fdba74"/> : <Square2StackIcon className="w-4 h-4"/>} Copy</Button>
            </motion.div>}
        </motion.div>
    </motion.div>
}

export default CleanTeacherQuizCard;