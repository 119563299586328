import React, {useEffect} from "react";
import {Player} from "../NewGameScreen";
import {Quiz} from "../../../backend/Tables";
import {Socket} from "../../../hooks/socket/UseSocket";
import first from "../../../img/game/medals/first.png";
import second from "../../../img/game/medals/second.png";
import third from "../../../img/game/medals/third.png";
import {useWindowSize} from "react-use";
import ReactConfetti from "react-confetti";
import FullDiv from "react-div-100vh";

const PodiumUX = ({player, quiz, teacher, socket, players, close}: {player: Player | null, quiz: Quiz | null, teacher: boolean, socket?: Socket, players: Player[], close: () => void}) => {
    // TODO: add next button for teacher

    const { width, height } = useWindowSize();

    useEffect(() => {
        delete localStorage.returngame;
    }, []);

    return <>
        <ReactConfetti width={width} height={height} numberOfPieces={400} recycle={false} gravity={0.15}/>
        <FullDiv className="flex flex-col bg-[#46178f]">
            <div className="bg-white shadow-md w-full py-5">
                <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                    <h1 className="text-center text-3xl font-bold select-none game-font">{quiz?.title || "..."}</h1>
                </div>
            </div>
            <div className="flex-1 flex flex-col items-center h-full">
                <div className="grid grid-cols-3 h-full flex-1">
                    <div className="w-64 flex flex-col justify-end items-center">
                        {players.length >= 2 && <>
                            <p className="text-white game-font font-bold text-4xl mb-3">{players[1].name}</p>
                            <div className="w-full shadow-xl bg-purple-700 rounded-t-lg flex flex-col items-center" style={{height: "50%"}}>
                                <img src={second} alt="Second place" className="mb-2"/>
                                <p className="game-font text-white font-bold text-2xl">{players[1].points} points</p>
                                <p className="game-font text-white font-bold text-lg">{players[1].points / 100} out of {quiz?.questions?.length || "..."}</p>
                            </div>
                        </>}
                    </div>

                    <div className="w-64 flex flex-col justify-end items-center">
                        {players.length >= 1 && <>
                            <p className="text-white game-font font-bold text-4xl mb-3">{players[0].name}</p>
                            <div className="w-full shadow-xl bg-purple-700 rounded-t-lg flex flex-col items-center" style={{height: "65%"}}>
                                <img src={first} alt="First place" className="mb-2"/>
                                <p className="game-font text-white font-bold text-2xl">{players[0].points} points</p>
                                <p className="game-font text-white font-bold text-lg">{players[0].points / 100} out of {quiz?.questions?.length || "..."}</p>
                            </div>
                        </>}
                    </div>

                    <div className="w-64 flex flex-col justify-end items-center">
                        {players.length >= 3 && <>
                            <p className="text-white game-font font-bold text-4xl mb-3">{players[2].name}</p>
                            <div className="w-full shadow-xl bg-purple-700 rounded-t-lg flex flex-col items-center" style={{height: "40%"}}>
                                <img src={third} alt="Third place" className="mb-2"/>
                                <p className="game-font text-white font-bold text-2xl">{players[2].points} points</p>
                                <p className="game-font text-white font-bold text-lg">{players[2].points / 100} out of {quiz?.questions?.length || "..."}</p>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </FullDiv>
        {players.length >= 5 && <div className="fixed bottom-5 left-1/2 -translate-x-1/2 bg-purple-900 rounded-lg shadow-md p-5">
            <h3 className="text-white text-2xl font-bold game-font mb-3 text-center">Runners-up</h3>
            <div className="flex gap-4">
                <div className="bg-purple-700 p-2 flex items-center gap-2.5 rounded-md">
                    <span className="game-font text-white font-extrabold rounded-[100%] bg-purple-800 border-2 border-gray-300 w-8 h-8 flex justify-center items-center">4</span>
                    <span className="game-font font-bold text-white text-lg">{players[3].name}</span>
                </div>
                <div className="bg-purple-700 p-2 flex items-center gap-2.5 rounded-md">
                    <span className="game-font text-white font-extrabold rounded-[100%] bg-purple-800 border-2 border-gray-300 w-8 h-8 flex justify-center items-center">5</span>
                    <span className="game-font font-bold text-white text-lg">{players[4].name}</span>
                </div>
            </div>
        </div>}
    </>
}

export default PodiumUX;
