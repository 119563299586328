import React, {createContext, Dispatch, SetStateAction} from 'react';
import {RouteObject, RouterProvider} from "react-router-dom";
import LoginScreen from "./pages/auth/LoginScreen";
import RegisterScreen from "./pages/auth/RegisterScreen";
import ConfirmScreen from "./pages/auth/ConfirmScreen";
import useTheme from "./hooks/ui/UseTheme";
import LogoutScreen from "./pages/auth/LogoutScreen";
import CleanTeacherHomeScreen from "./pages/teacher/CleanTeacherHomeScreen";
import CleanTeacherQuizzesScreen from "./pages/teacher/CleanTeacherQuizzesScreen";
import CleanTeacherCreateScreen from "./pages/teacher/CleanTeacherCreateScreen";
import CleanTeacherQuizScreen from "./pages/teacher/CleanTeacherQuizScreen";
import JoinGameScreen from "./pages/game/JoinGameScreen";
import NewGameScreen from "./pages/game/NewGameScreen";
import {FutureConfig as RouterFutureConfig, Router as RemixRouter} from "@remix-run/router/dist/router";
import {HydrationState} from "@remix-run/router";
import {Helmet} from "react-helmet";

const ThemeContext = createContext<[string, Dispatch<SetStateAction<string>>] | undefined>(['', () => {}]);

const App = ({createBrowserRouter}: {createBrowserRouter: (routes: RouteObject[], opts?: {basename?: string, future?: Partial<Omit<RouterFutureConfig, "v7_prependBasename">>, hydrationData?: HydrationState, window?: Window}) => RemixRouter}) => {
    const theme = useTheme();

    const game = window.location.hostname.endsWith(".fun");
    const playBase = game ? "/" : "/play";

    const router = createBrowserRouter([
        {
            path: game ? "/home" : "/",
            element: <CleanTeacherHomeScreen/>
        },
        {
            path: "/quizzes",
            element: <CleanTeacherQuizzesScreen/>
        },
        {
            path: "/quiz/:id",
            element: <CleanTeacherQuizScreen/>
        },
        {
            path: "/create",
            element: <CleanTeacherCreateScreen/>
        },
        {
            path: playBase,
            element: <JoinGameScreen/>
        },
        {
            path: playBase + "/:id",
            element: <NewGameScreen/>
        },
        {
            path: "/auth/login",
            element: <LoginScreen/>
        },
        {
            path: "/auth/register",
            element: <RegisterScreen/>
        },
        {
            path: "/auth/confirm",
            element: <ConfirmScreen/>
        },
        {
            path: "/auth/logout",
            element: <LogoutScreen/>
        }
    ]);

    const test = {
        key: String,
        key2: Number,
        key3: Boolean
    }

    return <>
        <Helmet>
            <title>PopQuiz</title>
        </Helmet>
        <ThemeContext.Provider value={theme}>
            <RouterProvider router={router}/>
        </ThemeContext.Provider>
    </>
}

export default App;
export {ThemeContext};
